import React, { useState, useEffect } from "react";
import HeaderWrapper from "./header.style";
import { iconDemo, iconAppName, iconAppNameNegative } from "helper/constant";

/*
import englishLang from "assets/images/uk.svg";
import chineseLang from "assets/images/china.svg";
import spanishLang from "assets/images/spain.svg";
import frenchLang from "assets/images/france.svg";
import italianLang from "assets/images/italy.svg";
import IntlMessages from "util/intlMessages";
import { Scrollbars } from "react-custom-scrollbars";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import {
    friend1,
    friend2,
    friend3,
    friend4,
    ProfileLockScreen
} from "helper/constant";
*/
import {
    InputGroup,
    InputGroupAddon,
    Input
} from 'reactstrap';
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import { AppName } from "helper/constant";
import GroceryList from "../floatingGroceryList/GroceryList";
import SearchBar from "../searchBar/SearchBar";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
// import NewListMobile from '../../assets/images/icons/shpo-list.png';
import NewListMobile from '../../assets/images/icons/shop_list.svg';
import LeafletListMobile from '../../assets/images/icons/index-icon.svg';
import { serverResponse } from '../../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../../settings/gaConfig';
import { isMobile } from "react-device-detect";

const { logout } = AuthActions;

const Header = props => {

    useEffect(() => {
        ReactGA.initialize(serverResponse.config.ga_tracking_id, {
    gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
  })
    });

    /*
    const [notificationPopoverOpen, setNotificationPopoverOpen] = useState(
        false
    );
    const [languagePopover, setLanguagePopover] = useState(false);
    const [profilePopover, setProfilePopover] = useState(false);
    */

    const { drawerMiniMethod, mini } = props;

    let [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => {
        setPopoverOpen(!popoverOpen);
        if (popoverOpen) {
            ReactGA.pageview("/" + serverResponse.config.release_id + "/lista-spesa");
        }
    }

    let [searchBarOpen, setSearchBarOpen] = useState(false);

    const searchBarToggle = () => {
        setSearchBarOpen(!searchBarOpen);
    }

    /*let [searchText, setSearchText] = useState("");

    const handleClickSearch = () => {
        props.history.push("/categories/" + searchText);
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    }*/

    const goToPage = () => {
        if (window.location.pathname == '/volantino') {
            window.location.reload();
        } else {
            props.history.push("/volantino");
        }
    }

    return (
        <HeaderWrapper {...props}>
            <div className="headerBack" style={{ marginRight: "80px" }}>
                <ul className="list-inline ma-0 custom-search-box">
                    <li className="list-inline-item feed-text-area-icon">
                        <div className="drawer-handle-arrow">
                            {mini ? (
                                <button
                                    className="top-header-icon c-btn"
                                    onClick={drawerMiniMethod()}
                                >
                                    <i className="fas fa-arrow-right" />
                                </button>
                            ) : (
                                    <button
                                        className="top-header-icon c-btn"
                                        onClick={drawerMiniMethod()}
                                    >
                                        <i className="fas fa-arrow-left" />
                                    </button>
                                )}
                        </div>
                        <div
                            className="mini-drawer-menu-icon"
                            onClick={drawerMiniMethod()}
                        >
                            <i className="fas fa-bars" />{" "}
                            {!isMobile &&
                                <span className="app-name">{AppName}</span>
                            }
                            {isMobile && !serverResponse.config.no_link_to_site &&
                                <a href="https://interattivo.net/" target="_blank"><img src={iconAppNameNegative} alt="react-logo" style={{ width:"80%", maxHeight: '28px', marginTop: '10px', 'marginLeft': '10px' }} /></a>
                            }
                            {isMobile && serverResponse.config.no_link_to_site &&
                                <img src={iconAppNameNegative} alt="react-logo" style={{ maxHeight: '28px', marginTop: '10px', 'marginLeft': '10px' }} />
                            }
                        </div>
                    </li>

                    {/* grocery icon */}
                    {!serverResponse.config.hide_grocery_list &&
                        <li className="list-inline-item feed-text-area-icon pull-right mobile-grocery-list">
                            <button
                                className="top-header-icon c-btn"
                                id="mobile-grocery"
                                onClick={toggle}
                            >

                                <img id="" src={NewListMobile} style={{ cursor: "pointer", width: "auto", height: "24px", position: "relative", top: '-1px' }} alt="list" />
                                <NotificationBadge count={props.groceryList.length} effect={Effect.SCALE} className="custom-mobile-grocery-notification-badge" />

                            </button>
                            <div className="iconTopName">LISTA SPESA</div>
                        </li>
                    }

                    {/* search icon */}
                    <li id="mobile-search-hidden" className="list-inline-item feed-text-area-icon pull-right mobile-grocery-list">
                        <button
                            className="top-header-icon c-btn"
                            id="mobile-search"
                            onClick={searchBarToggle}
                        >
                            <i className="fas fa-search" />
                        </button>
                    </li>
                    {serverResponse.config.has_pages &&
                    <li className="list-inline-item feed-text-area-icon pull-right mobile-grocery-list">
                        <div
                            className="top-header-icon c-btn"
                            id="mobile-volantino"
                            onClick={goToPage}
                        >
                            <i id="mobile_index_icon" className="fas fa-list-ul"></i>
                            {/* <i class="fas fa-list-alt"></i> */}
                        </div>
                        <div className="iconTopName">INDICE</div>
                    </li>
                    }
                    {/* search bar */}
                    {
                        !serverResponse.config.hide_searchbar
                        &&
                        props.location.pathname !== '/categories'
                        &&
                        !props.location.pathname.startsWith('/products')
                        &&
                        <li className="list-inline-item custom-search-li">

                            <InputGroup>
                                <Input
                                    type="text"
                                    name="serach"
                                    id="search"
                                    placeholder="Cerca un prodotto"
                                    value={props.searchText}
                                    onChange={props.handleSearchTextChange}
                                    onKeyUp={(e) => {
                                        props.handleSearch2(props.searchText);
                                        /*if (e.keyCode === 13) {
                                            handleClickSearch()
                                        } else {
                                            setSearchText(e.target.value)
                                        }*/
                                    }}
                                />
                                <InputGroupAddon addonType="append"><span className="input-group-text" ><FontAwesomeIcon icon={faSearch} /></span></InputGroupAddon>
                            </InputGroup>

                        </li>

                    }



                </ul>
                {!serverResponse.config.hide_grocery_list &&
                    <GroceryList
                        isOpen={popoverOpen}
                        toggle={toggle}
                        placement={"bottom"}
                        target="mobile-grocery"
                        isMobile={true} />
                }
                <SearchBar
                    isOpen={searchBarOpen}
                    toggle={searchBarToggle}
                    placement={"bottom"}
                    target="mobile-search"
                    isMobile={true} />

            </div>
        </HeaderWrapper>
    );
};

const mapStateToProps = state => {
    return {
        groceryList: state.groceryList.list,
    };
}

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        { logout }
    )
)(Header);
