import { serverResponse } from '../fakeServer';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../../settings/gaConfig';

const sidebar_btns = {
  leaflet: {
    name: 'sidebar.leaflet',
    routepath: '/leaflet',
    iconClass: 'fas fa-book-open',
    action: (e) => { }
  },
  catalog: {
    name: 'Catalogo',
    routepath: '/leaflet',
    iconClass: 'fas fa-book-open',
    action: (e) => { }
  },
  menu: {
    name: 'Menù',
    routepath: '/leaflet',
    iconClass: 'fas fa-book-open',
    action: (e) => { }
  },
  pages: {
    name: 'sidebar.pages',
    routepath: '/pages',
    iconClass: 'fas fa-columns',
    action: (e) => { }
  },
  pdf: {
    name: 'sidebar.pdf',
    routepath: '/Pdf',
    iconClass: 'fas fa-file-pdf',
    action: (e) => {
      e.preventDefault();
      if (serverResponse.config.ga_active) {
        ReactGA.event({
          category: serverResponse.config.release_id,
          action: gaEventLabel.open_pdf,
          label: 'PDF'
        });
      }
      if (window.ReactNativeWebView != undefined) {
        var obj = { action: "download", value: 'https://' + window.location.host + '/' + serverResponse.config.pdfPath };
        window.ReactNativeWebView.postMessage(JSON.stringify(obj));
      } else {
        window.open(process.env.PUBLIC_URL + serverResponse.config.pdfPath, '_blank');
      }
    }
  },
  products: {
    name: 'sidebar.categories',
    routepath: '/categories',
    iconClass: (isMobile) ? 'fas fa-search' : 'fas fa-list-ul',
    action: (e) => { }
  },
  full_screen: {
    name: 'sidebar.fullscreen',
    id: 'fullScreenId',
    routepath: '/fullScreen',
    iconClass: 'fas fa-arrows-alt',
    action: (e) => {
      e.preventDefault();
      //var routeHeight =document.getElementById("root").firstChild;
      var routeHeight = window.document.documentElement;
      if (routeHeight.requestFullscreen) {
        routeHeight.requestFullscreen();
      } else if (routeHeight.mozRequestFullScreen) { /* Firefox */
        routeHeight.mozRequestFullScreen();
      } else if (routeHeight.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        routeHeight.webkitRequestFullscreen();
      } else if (routeHeight.msRequestFullscreen) { /* IE/Edge */
        routeHeight.msRequestFullscreen();
      }
    }
  }
}

var sidebarData = [];

serverResponse.config.sidebar.map((btn) => {
  if (btn != 'share') {
    sidebarData.push(sidebar_btns[btn])
  }
});

if (serverResponse.config.only_browsable || serverResponse.config.hide_product_page) {
  var removed = false;
  var i = 0;
  while (!removed && i < sidebarData.length) {
    if (sidebarData[i].name == 'sidebar.categories') {
      sidebarData.splice(i, 1);
      removed = true;
    }
    i++;
  }
}

export { sidebarData };