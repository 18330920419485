const leaflets = {
    "client": {
        "config": {
            "primary_color": "#276BB4",
            "secondary_color": "#F5A719",
            "tertiary_color": "#FFE90D",
            "hover_color": "rgba(220, 35, 17, 0.5)",
            "clientIcon": "/media/images/logo.png",
            "serverPath": "villabebe.interattivo.net/",
            "pdfPath": "/media/pdf/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al volantino di Villa bebè",
            "shareFlyerURL": "https://villabebe.interattivo.net?v=612",
            "shareProductSentence": "Guarda questa fantastica offerta!",
            "ogTitleMeta": "Villa bebè | Volantino Interattivi",
            "ogDescriptionMeta": "Dai uno sguardo alla nostro volantino",
            "ogImageMeta": "/media/images/fb.png",
            "ogImageMeta_mobile": "/media/images/wa.jpg",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": false,
            "ga_tracking_id": "UA-152069090-7",
            "release_id": "1",
            "send_grocery_list_to_market": false,
            "grocery_list_min_value": 10,
            "client_id": 3,
            "signboard_id": 3,
            "has_pages": true,
            "hide_grocery_list": false,
            "hide_searchbar": false,
            "line_through": false,
            "category_banner": "",
            "category_banner_mobile": "",
            "hide_plus_product": false,
            "hide_plus_product_price": false,
            "type": "leaflet",
            "product_banner": "",
            "href_banner": "",
            "internal_banner_click": false,
            "external_banner_click": false,
            "add_icon_list": true,
            "marker_product_in_list": false,
            "marker": "ellipse_black",
            "only_browsable": false,
            "hide_product_page": false,
            "noIconAnimated": "marker vertical",
            "version": 613,
            "sidebar": [
                'leaflet',
                'products',
                'pages',
                'pdf',
                'full_screen',
                'share'
            ]
        },
        "video": {
            "show_only_once": true,
            "mandatory_seconds": 4,
            "videos": [
                {
                    page: 1,
                    url: "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/video/Spot_Villa_Bebe.mp4",
                    item_id: "185218"
                },
            ],
        },
        "leaflet": {
            "name": "Villa Bebè demo",
            "code": "613",
            "id_campaign": "613",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/Indice-villabebe_vtzmpKn.png",
                "links": [
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 25.15658061785866,
                            "left": 10.794364508393286,
                            "width": 81.34142685851319,
                            "height": 4.992594159966144
                        }
                    },
                    {
                        "page": 4,
                        "blueprint": {
                            "top": 30.149174777824797,
                            "left": 10.794364508393286,
                            "width": 81.34142685851319,
                            "height": 5.70884468895472
                        }
                    },
                    {
                        "page": 6,
                        "blueprint": {
                            "top": 35.85801946677952,
                            "left": 10.794364508393286,
                            "width": 81.34142685851319,
                            "height": 5.712018620397798
                        }
                    },
                    {
                        "page": 10,
                        "blueprint": {
                            "top": 41.570038087177316,
                            "left": 10.794364508393286,
                            "width": 81.34142685851319,
                            "height": 5.618916631400762
                        }
                    },
                    {
                        "page": 12,
                        "blueprint": {
                            "top": 47.188954718578074,
                            "left": 10.794364508393286,
                            "width": 81.34142685851319,
                            "height": 5.712018620397798
                        }
                    },
                    {
                        "page": 14,
                        "blueprint": {
                            "top": 52.900973338975874,
                            "left": 10.794364508393286,
                            "width": 81.34142685851319,
                            "height": 5.70884468895472
                        }
                    },
                    {
                        "page": 14,
                        "blueprint": {
                            "top": 58.609818027930594,
                            "left": 10.794364508393286,
                            "width": 81.34142685851319,
                            "height": 5.712018620397798
                        }
                    },
                    {
                        "page": -1,
                        "type": "url",
                        "url": "https://www.interattivo.net",
                        "target": "_blank",
                        "blueprint": {
                            "top": 78.17631260203275,
                            "left": 33.09758281110116,
                            "width": 34.438973440763945,
                            "height": 4.703775870240665
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Abbigliamento",
                    "subcategories": [
                        "Scarpe",
                        "Abbigliamento"
                    ]
                },
                {
                    "category_name": "Prima infanzia",
                    "subcategories": [
                        "Puericultura"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_1_vZWH6mJ.jpg",
                    "interactivities": [
                        // {
                        //     "item_id": "cstm44048374",
                        //     "field1": "Villabebè",
                        //     "field2": "",
                        //     "field3": "",
                        //     "field4": "",
                        //     "description": "Villabebè",
                        //     "grammage": null,
                        //     "price": "0.00",
                        //     "price_label": "",
                        //     "equivalence": null,
                        //     "quantity_step": null,
                        //     "grocery_label": "",
                        //     "varieties": [],
                        //     "price_for_kg": null,
                        //     "available_pieces": null,
                        //     "max_purchasable_pieces": null,
                        //     "points": "",
                        //     "fidelity_product": false,
                        //     "focus": false,
                        //     "pam": true,
                        //     "three_for_two": false,
                        //     "one_and_one_gratis": false,
                        //     "underpriced_product": false,
                        //     "category": "Horeca",
                        //     "subcategory": "Professional",
                        //     "blueprint": {
                        //         "top": 0.0,
                        //         "left": 0.0,
                        //         "width": 100.0,
                        //         "height": 15.720787640416086
                        //     },
                        //     "images": [
                        //         {
                        //             "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_5hAcP2I_fg6HWXx.png",
                        //             "cropped": true
                        //         }
                        //     ],
                        //     "markers": [
                        //         {
                        //             "type": "video",
                        //             "title": "",
                        //             "data": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/video/Spot_Villa_Bebe.mp4"
                        //         }
                        //     ]
                        // }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_2_FAiotu9.jpg",
                    "interactivities": [
                        {
                            "item_id": "cstm73833463",
                            "field1": "Scarpine",
                            "field2": "chicco",
                            "field3": "bimbo/ bimba",
                            "field4": "",
                            "description": "Scarpine chicco bimbo/ bimba",
                            "grammage": null,
                            "price": "39.99",
                            "price_label": "€ 39,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bimbo",
                                "Bimba"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 7.885697460161356,
                                "left": 4.604583309029032,
                                "width": 90.95011404989312,
                                "height": 25.311882277915082
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_u9hajMt.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_1_x9sMrbY.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_2_4p7K6gM.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_obGWMPr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Sneakers sportiva doppio velcro con dettagli fluo. Suola con led luminosi multicolor, super leggera. Forma arrotondata. Regolabile con doppio velcro \"Impronta Naturale Chicco\": l'esclusivo sottopiede che asseconda il naturale sviluppo dell'arco plantare Batterie : non sostituibili / Circuito led : impermeabile Certificazione CE / RAEE : la calzatura ha al suo interno un circuito elettrico/batterie, quindi classificato come rifiuto speciale. Al termine della propria vita dovrà essere conferito all’isola ecologica, nel raccoglitore dei piccoli elettrodomestici. (Fonte: chicco.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/images/cq5dam.web.1280.1280.jpeg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm69995715",
                            "field1": "Galosce pioggine",
                            "field2": "chicco",
                            "field3": "bimbo/ bimba",
                            "field4": "da 24 a 32",
                            "description": "Galosce pioggine chicco bimbo/ bimba da 24 a 32",
                            "grammage": null,
                            "price": "19.90",
                            "price_label": "€ 19,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bimbo",
                                "Bimba"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 33.8330320780162,
                                "left": 4.604583309029032,
                                "width": 56.53692999357738,
                                "height": 25.311882277915082
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_3_ZW7stMB.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_4_6ncvGwU.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_5_Anl2OO3.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_6_s2XB6I3.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_3tZDLYT_PLcrA9P.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "Scheda tecnica",
                                    "data": {
                                        "specifiche": "Stivale pioggia con suola a led luminosi multicolor, forma arrotondata, Batterie : non sostituibili, Circuito led : impermeabile, Certificazione CE / RAEE",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/images/cq5dam.web.1280.1280_3.jpeg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm09860202",
                            "field1": "Galosce pioggine",
                            "field2": "chicco",
                            "field3": "bimbo/ bimba",
                            "field4": "da 22 a 25",
                            "description": "Galosce pioggine chicco bimbo/ bimba da 22 a 25",
                            "grammage": null,
                            "price": "16.99",
                            "price_label": "€ 16,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bimbo",
                                "Bimba"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 34.168276238609906,
                                "left": 62.52628286725524,
                                "width": 32.95911679424073,
                                "height": 25.347892550527856
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_7_GOkWtYM.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_8_D5E96Qf.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_9_H6wBxBI.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cq5dam.web.1280.1280_10_BdAvlzq.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_PumfCSQ_xUbKIJN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "Avvertenze",
                                    "data": {
                                        "specifiche": "Non lavare in lavatrice, Rimuovere lo sporco con un panno umido e lasciare asciugare il prodotto lontano da fonti di calore, Sconsigliato l’utilizzo di apparecchi elettronici per l’asciugatura",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/images/cq5dam.web.1280.1280_7.jpeg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm88266019",
                            "field1": "Pantofoline",
                            "field2": "de fonseca",
                            "field3": "bimbo/ bimba",
                            "field4": "",
                            "description": "Pantofoline de fonseca bimbo/ bimba",
                            "grammage": null,
                            "price": "14.90",
                            "price_label": "€ 14,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bimbo",
                                "Bimba"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 60.54378404170179,
                                "left": 41.56332360220476,
                                "width": 52.83151729243186,
                                "height": 12.417043932141135
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/8051931208074_1_o0KJAR8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/8051931208159_1_M22GGAZ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_r7M7cei_4oxICC6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm46099466",
                            "field1": "Pantofole",
                            "field2": "de fonseca",
                            "field3": "bimbo/ bimba",
                            "field4": "",
                            "description": "Pantofole de fonseca bimbo/ bimba",
                            "grammage": null,
                            "price": "12.90",
                            "price_label": "€ 12,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bimbo",
                                "Bimba"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 72.96082797384291,
                                "left": 41.56332360220476,
                                "width": 52.83151729243186,
                                "height": 22.093770070648684
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/8051931206605_1_f1Hgeim.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/8051931206650_1_OfE5fTp.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_WmShXe5_NZZYwbs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_3_o1TtSmB.jpg",
                    "interactivities": [
                        {
                            "item_id": "cstm96630910",
                            "field1": "Scarpine",
                            "field2": "adidas",
                            "field3": "bimbo",
                            "field4": "da 20 a 34",
                            "description": "Scarpine adidas bimbo da 20 a 34",
                            "grammage": null,
                            "price": "44.90",
                            "price_label": "€ 44,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 13.53029460576228,
                                "left": 26.659014908258325,
                                "width": 34.65553784477611,
                                "height": 13.273390302615205
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_XHwMZnJ_NHwfB8O.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm18131161",
                            "field1": "Scarpine",
                            "field2": "adidas",
                            "field3": "bimbo",
                            "field4": "da 20 a 24",
                            "description": "Scarpine adidas bimbo da 20 a 24",
                            "grammage": null,
                            "price": "44.90",
                            "price_label": "€ 44,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 26.803684908377488,
                                "left": 26.659014908258325,
                                "width": 34.65553784477611,
                                "height": 12.331406551059729
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/Scarpe_Lite_Racer_2.0_Blu_GW4823_06_standard_INE424d.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_bGqMsUu_XAx725N.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm46692428",
                            "field1": "Scarpine",
                            "field2": "adidas",
                            "field3": "bimbo",
                            "field4": "da 20 a 34",
                            "description": "Scarpine adidas bimbo da 20 a 34",
                            "grammage": null,
                            "price": "34.90",
                            "price_label": "€ 34,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 39.13509145943721,
                                "left": 26.659014908258325,
                                "width": 34.65553784477611,
                                "height": 12.588310854206807
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_q7L2iVh_UTR5S5u.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm43339463",
                            "field1": "Scarpine",
                            "field2": "adidas",
                            "field3": "bimba",
                            "field4": "da 28 a 34",
                            "description": "Scarpine adidas bimba da 28 a 34",
                            "grammage": null,
                            "price": "34.90",
                            "price_label": "€ 34,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 14.907749066189574,
                                "left": 61.900455129399234,
                                "width": 31.211735661089534,
                                "height": 12.124127396356998
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_ib4ftln_bExnuTr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm86394174",
                            "field1": "Scarpine",
                            "field2": "adidas",
                            "field3": "bimba",
                            "field4": "da 20 a 34",
                            "description": "Scarpine adidas bimba da 20 a 34",
                            "grammage": null,
                            "price": "39.90",
                            "price_label": "€ 39,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 27.031876462546574,
                                "left": 61.900455129399234,
                                "width": 31.211735661089534,
                                "height": 12.281783362639668
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/Scarpe_Lite_Racer_2.0_Rosa_GZ7843_01_standard_lOhQkD8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_MfbSymF_VSrA7Vu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm57562063",
                            "field1": "Scarpine",
                            "field2": "adidas",
                            "field3": "bimba",
                            "field4": "da 28 a 34",
                            "description": "Scarpine adidas bimba da 28 a 34",
                            "grammage": null,
                            "price": "39.90",
                            "price_label": "€ 39,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Scarpe",
                            "blueprint": {
                                "top": 39.31365982518624,
                                "left": 61.900455129399234,
                                "width": 31.211735661089534,
                                "height": 13.23738003000243
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_fJJwunB_898EsVj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm29793481",
                            "field1": "Tute",
                            "field2": "adidas",
                            "field3": "bimbo/ bimba",
                            "field4": "da 9 a 36 mesi",
                            "description": "Tute adidas bimbo/ bimba da 9 a 36 mesi",
                            "grammage": null,
                            "price": "34.90",
                            "price_label": "€ 34,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bimbo",
                                "Bimba"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 66.71678484086644,
                                "left": 5.312570600074767,
                                "width": 39.45151485964972,
                                "height": 26.33949949050339
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/Tuta_Badge_of_Sport_Blu_GT9504_01_laydown_vMbeqQA.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/Tuta_Badge_of_Sport_Rosa_H28836_01_laydown_jN8ALu3.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_ebqgCYx_8PbiFyU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.villabebe.it/"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm26160325",
                            "field1": "Tute",
                            "field2": "adidas",
                            "field3": "bimbo/ bimba",
                            "field4": "da 3 a 10 anni",
                            "description": "Tute adidas bimbo/ bimba da 3 a 10 anni",
                            "grammage": null,
                            "price": "59.90",
                            "price_label": "€ 59,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bimbo",
                                "Bimba"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 64.2333752704285,
                                "left": 55.478273875604636,
                                "width": 39.45151485964972,
                                "height": 28.994179903055574
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_rpSoryL_K2kAi9n.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_4_R8A42Xy.jpg",
                    "interactivities": [
                        {
                            "item_id": "cstm54025689",
                            "field1": "T-shirt",
                            "field2": "Melby",
                            "field3": "manica lunga",
                            "field4": "",
                            "description": "T-shirt Melby manica lunga",
                            "grammage": null,
                            "price": "14.90",
                            "price_label": "€ 14,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 15.935362358729314,
                                "left": 2.6467565831727726,
                                "width": 25.75894737366154,
                                "height": 18.032932248812642
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_oLrPVJl_BwvpUh5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm32525912",
                            "field1": "Felpa",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Felpa Melby",
                            "grammage": null,
                            "price": "21.90",
                            "price_label": "€ 21,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 17.13424845340758,
                                "left": 28.40570395683432,
                                "width": 25.497314668727665,
                                "height": 22.229036520223
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_BSVVUzO_cRJitwy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm89954174",
                            "field1": "Gonna",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Gonna Melby",
                            "grammage": null,
                            "price": "19.90",
                            "price_label": "€ 19,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 39.36328497363059,
                                "left": 28.405703956834312,
                                "width": 25.497314668727665,
                                "height": 16.64914867604285
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_j9J7MvW_DRSZHr0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm95324347",
                            "field1": "Leggings",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Leggings Melby",
                            "grammage": null,
                            "price": "12.90",
                            "price_label": "€ 12,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 34.91027443904886,
                                "left": 4.8278713434570975,
                                "width": 23.577832613377215,
                                "height": 21.10215921062457
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_PHw5GSM_bXZ3HNu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm79624116",
                            "field1": "Maxi maglia",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Maxi maglia Melby",
                            "grammage": null,
                            "price": "21.90",
                            "price_label": "€ 21,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 21.808154978547897,
                                "left": 54.387712335310916,
                                "width": 21.275538185428335,
                                "height": 29.58000121447814
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_yHhzJc1_jLedO6k.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "",
                                    "data": {
                                        "open_modal": true,
                                        "video_type": "video_file",
                                        "link": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/video/MELBY_AI_2021_BTS.mp4"
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm91447769",
                            "field1": "T-shirt",
                            "field2": "Melby",
                            "field3": "manica luga",
                            "field4": "",
                            "description": "T-shirt Melby manica luga",
                            "grammage": null,
                            "price": "14.90",
                            "price_label": "€ 14,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 16.584433494549224,
                                "left": 75.66325052073927,
                                "width": 21.792112356588945,
                                "height": 19.38946450965215
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_uEfWUNI_ZNnYuEr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm54510191",
                            "field1": "Leggings",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Leggings Melby",
                            "grammage": null,
                            "price": "12.90",
                            "price_label": "€ 12,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 35.97389800420138,
                                "left": 75.66325052073927,
                                "width": 21.792112356588945,
                                "height": 19.146180962409503
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_pmCtDvP_gBqg1Ib.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm53058945",
                            "field1": "T-shirt",
                            "field2": "Melby",
                            "field3": "manica lunga",
                            "field4": "",
                            "description": "T-shirt Melby manica lunga",
                            "grammage": null,
                            "price": "11.90",
                            "price_label": "€ 11,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 55.890793836076405,
                                "left": 65.00001664060619,
                                "width": 28.698984546698128,
                                "height": 18.533118139178086
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_hKXx7xA_Dg7Nynr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm86783793",
                            "field1": "Felpa",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Felpa Melby",
                            "grammage": null,
                            "price": "39.90",
                            "price_label": "€ 39,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 55.890793836076405,
                                "left": 34.44530818794658,
                                "width": 30.554708452659604,
                                "height": 18.533118139178086
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_OAPDy52_tUNZFlk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm41347108",
                            "field1": "Felpa",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Felpa Melby",
                            "grammage": null,
                            "price": "27.90",
                            "price_label": "€ 27,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 55.890793836076405,
                                "left": 3.565203334095399,
                                "width": 30.880104853851183,
                                "height": 18.533118139178086
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_zGsyOCx_CnRhmIw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm40108338",
                            "field1": "Pantalone tuta",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Pantalone tuta Melby",
                            "grammage": null,
                            "price": "14.90",
                            "price_label": "€ 14,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 74.42391197525448,
                                "left": 3.565203334095399,
                                "width": 30.880104853851183,
                                "height": 19.916889951802165
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_fSyfcXH_Lp7UZs0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm58177491",
                            "field1": "Pantalone",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Pantalone Melby",
                            "grammage": null,
                            "price": "22.90",
                            "price_label": "€ 22,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 74.42391197525448,
                                "left": 34.44530818794658,
                                "width": 32.735825986378295,
                                "height": 19.916889951802165
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_K2x2dH9_AUi697D.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm86833389",
                            "field1": "Jeans",
                            "field2": "Melby",
                            "field3": "",
                            "field4": "",
                            "description": "Jeans Melby",
                            "grammage": null,
                            "price": "22.90",
                            "price_label": "€ 22,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 74.42391197525448,
                                "left": 67.18113417432487,
                                "width": 26.396679025011792,
                                "height": 19.916889951802165
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_bH3Xjr4_VRB7hRn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_5_z44dKEM.jpg",
                    "interactivities": [
                        {
                            "item_id": "cstm87591524",
                            "field1": "Pigiami",
                            "field2": "Melby",
                            "field3": "bimbo",
                            "field4": "",
                            "description": "Pigiami Melby bimbo",
                            "grammage": null,
                            "price": "24.90",
                            "price_label": "€ 24,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 8.485141487512633,
                                "left": 2.838175249926625,
                                "width": 32.09181832746581,
                                "height": 41.582488797238106
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_TqvdpbO_klJF0ij.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm11778377",
                            "field1": "Pigiami",
                            "field2": "Melby",
                            "field3": "bimbo",
                            "field4": "",
                            "description": "Pigiami Melby bimbo",
                            "grammage": null,
                            "price": "24.90",
                            "price_label": "€ 24,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 8.306573121763607,
                                "left": 36.95873474630701,
                                "width": 30.293302777338727,
                                "height": 43.15992292870905
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_D1IXmMl_Yz4epW3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm67654917",
                            "field1": "Pigiami",
                            "field2": "Melby",
                            "field3": "bimbo",
                            "field4": "",
                            "description": "Pigiami Melby bimbo",
                            "grammage": null,
                            "price": "24.90",
                            "price_label": "€ 24,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 8.306573121763607,
                                "left": 67.25203752364574,
                                "width": 29.687429399925236,
                                "height": 43.15992292870905
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_BvoRgim_dzPXZLb.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm56102552",
                            "field1": "Pigiami",
                            "field2": "Melby",
                            "field3": "bimba",
                            "field4": "",
                            "description": "Pigiami Melby bimba",
                            "grammage": null,
                            "price": "24.90",
                            "price_label": "€ 24,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 51.38086258943982,
                                "left": 67.13087172315299,
                                "width": 29.687429399925236,
                                "height": 43.15992292870905
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_kfcGk36_x1siNbs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm79506402",
                            "field1": "Pigiami",
                            "field2": "Melby",
                            "field3": "bimba",
                            "field4": "",
                            "description": "Pigiami Melby bimba",
                            "grammage": null,
                            "price": "24.90",
                            "price_label": "€ 24,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 51.38086258943982,
                                "left": 35.62583883159346,
                                "width": 31.505032891559537,
                                "height": 43.15992292870905
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_0dOQ1vf_rGHltYD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm63712833",
                            "field1": "Pigiami",
                            "field2": "Melby",
                            "field3": "bimba",
                            "field4": "",
                            "description": "Pigiami Melby bimba",
                            "grammage": null,
                            "price": "24.90",
                            "price_label": "€ 24,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Abbigliamento",
                            "blueprint": {
                                "top": 51.38086258943982,
                                "left": 4.241980060829752,
                                "width": 31.38385877076371,
                                "height": 43.15992292870905
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_Vmmu937_SloWjTi.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_6_9h2W0Pe.jpg",
                    "interactivities": [
                        {
                            "item_id": "cstm32696273",
                            "field1": "Kit coniglio",
                            "field2": "",
                            "field3": "cameretta + trio",
                            "field4": "",
                            "description": "Kit coniglio cameretta + trio",
                            "grammage": null,
                            "price": "1228.00",
                            "price_label": "€ 1228,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Prima infanzia",
                            "subcategory": "Puericultura",
                            "blueprint": {
                                "top": 7.964033397559408,
                                "left": 2.1820283276037964,
                                "width": 96.21152527208272,
                                "height": 28.430744188292792
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/download_AANdqZg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/download_1_K53GiTs.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_ZCn76pg_3y23E1E.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.camspa.it/prodotti/cameretta-coniglio"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm05886998",
                            "field1": "Kit golosone",
                            "field2": "",
                            "field3": "cameretta+ trio",
                            "field4": "",
                            "description": "Kit golosone cameretta+ trio",
                            "grammage": null,
                            "price": "1318.00",
                            "price_label": "€ 1318,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Prima infanzia",
                            "subcategory": "Puericultura",
                            "blueprint": {
                                "top": 37.16549049529343,
                                "left": 2.424382116064177,
                                "width": 96.21152527208272,
                                "height": 28.430744188292792
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/download_2_dRbdWIt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/download_4_Hnl1uo0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_Gu1n9ZF_kywwM0H.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Preparati all’arrivo del tuo bambino. Acquista tutti i prodotti per la nuova cameretta. Lettino, bagnetto fasciatoio e completo piumone in coordinato, subito a casa tua. (Fonte: foppapedretti.it/)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/images/download_2.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm63819290",
                            "field1": "Peg-Pérego",
                            "field2": "set veloce modular",
                            "field3": "",
                            "field4": "",
                            "description": "Peg-Pérego set veloce modular",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "Scopri le novità!",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Prima infanzia",
                            "subcategory": "Passeggini",
                            "blueprint": {
                                "top": 66.20297623457228,
                                "left": 2.1620600999894672,
                                "width": 96.02034195524497,
                                "height": 29.07980944403984
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/download_9_ZfgGqPJ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_cX0aaFu_7mlpAcE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "video",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/video/Veloce_Lounge_Modular_-_Tutorial_Peg_Perego.mp4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_7_DFdgZpH.jpg",
                    "interactivities": [
                        {
                            "item_id": "cstm69030265",
                            "field1": "Kit my little love",
                            "field2": "",
                            "field3": "cameretta + trio",
                            "field4": "",
                            "description": "Kit my little love cameretta + trio",
                            "grammage": null,
                            "price": "1448.00",
                            "price_label": "€ 1448,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Prima infanzia",
                            "subcategory": "Puericultura",
                            "blueprint": {
                                "top": 37.16549049529343,
                                "left": 2.424382116064177,
                                "width": 96.21152527208272,
                                "height": 28.430744188292792
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/download_5_pTi3NOf.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/download_7_BblH8Cu.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_M0NGTj2_lM77SZZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Il lettino in legno My Little Love grigio di Foppapedretti ha una sponda regolabile a due altezze, per facilitare il riordino del lettino. La sponda può essere posizionata sotto il lettino per trasformarlo in un comodo divanetto. Anche la rete è regolabile a due altezze, per consentire un pratico uso anche quando il bimbo è neonato. Una testata del lettino, all’insegna del grigio, è decorata con un simpatico orsetto in rilievo. Il lettino ha un ampio cassetto inferiore, suddiviso in due scomparti, e ruote gommate antigraffio, con sistema frenante, per facilitare gli spostamenti. La struttura, solida e durevole nel tempo, è verniciata con vernici atossiche… ed è 100% made in Italy! Il bagnetto fasciatoio in legno My Little Love di Foppapedretti è trasformabile in comoda cassettiera. È dotato di vaschetta per il bagnetto estraibile, tre ampi cassetti decorati da un simpatico orsetto in rilievo, piano ribaltabile con sistema di sicurezza ammortizzato e pratico materassino. Ha ruote gommate antigraffio, con sistema frenante, per facilitare gli spostamenti. Il completo piumone My Little Love di Foppapedretti è caratterizzato da ricami con il simpatico orsetto che caratterizza la collezione. Il set tessile è composto da paracolpi, sacco sfoderabile, imbottitura e federa per cuscino. (Fonte: foppapedretti.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/images/download_5.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm53286528",
                            "field1": "Kit bubù",
                            "field2": "",
                            "field3": "cameretta + trio",
                            "field4": "",
                            "description": "Kit bubù cameretta + trio",
                            "grammage": null,
                            "price": "1398.00",
                            "price_label": "€ 1398,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Prima infanzia",
                            "subcategory": "Puericultura",
                            "blueprint": {
                                "top": 7.964037317607979,
                                "left": 2.303205221833987,
                                "width": 96.21152527208272,
                                "height": 28.430744188292792
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/download_8_j7VBBD9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/download_6_YG4V9Ik.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_61eZgwJ_Yg3wV0o.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm54838848",
                            "field1": "Electa",
                            "field2": "inglesina",
                            "field3": "",
                            "field4": "",
                            "description": "Electa inglesina",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Prima infanzia",
                            "subcategory": "Passeggini",
                            "blueprint": {
                                "top": 66.20297623457228,
                                "left": 2.1620600999894672,
                                "width": 96.02034195524497,
                                "height": 29.07980944403984
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/products/cropped_image_cdyc4rm_hxuuKBK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "video",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/video/ELECTA_-_A_STAR_IN_THE_CITY..mp4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 8,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_8_JxOOUKv.jpg",
                    "interactivities": []
                },
                {
                    "number": 9,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_9_hbxnCwE.jpg",
                    "interactivities": []
                },
                {
                    "number": 10,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_10_bMrXvEp.jpg",
                    "interactivities": []
                },
                {
                    "number": 11,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_11_oQxFuOj.jpg",
                    "interactivities": []
                },
                {
                    "number": 12,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_12_oLkVQTI.jpg",
                    "interactivities": []
                },
                {
                    "number": 13,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_13_s5bLjqU.jpg",
                    "interactivities": []
                },
                {
                    "number": 14,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_14_EU3hVcE.jpg",
                    "interactivities": []
                },
                {
                    "number": 15,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_15_jmlKZ8L.jpg",
                    "interactivities": []
                },
                {
                    "number": 16,
                    "image_file": "https://interattivo.s3.amazonaws.com/QjiUTFDUCD/pages/page_16_JUwEs9B.jpg",
                    "interactivities": []
                }
            ]
        }
    }

}

const lefletSwitch = () => {
    return leaflets.client;
}

export const serverResponse = lefletSwitch();